.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.major-mono-display-regular {
  font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal;
}

.rubik-mono-one-regular {
  font-family: "Rubik Mono One", monospace;
  font-weight: 400;
  font-style: normal;
}

.amatic-sc-regular {
  font-family: "Amatic SC", sans-serif;
  font-weight: 600;
  font-size: larger;
  font-style: normal;
}

.amatic-sc-bold {
  font-family: "Amatic SC", sans-serif;
  font-weight: 600;
  font-size: xxx-large;
  font-style: normal;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.react-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  overflow: auto;
}




